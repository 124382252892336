import React, { useEffect, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import Axios from 'axios';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip, Modal, notification, Spin, Select, Alert } from 'antd';
import { UserContext } from '../../App';
import { CareerTable } from '../../components/tables';
import { SERVER_URL } from '../../config';
import { ExportToExcel } from '../../components/csv';
import STATES from '../../lib/states.json';

const queryClient = new QueryClient();

let filterData = {
  careerName: undefined,
  occCode: undefined,
  year: 2023,
  state: 'United States',
  page: 1,
  pageSize: 10,
};

const Careers = () => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [filter, setFilter] = useState(filterData);
  const [isWorking, setWorking] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [modal, setModal] = useState({ visible: false, id: null, careerName: null, wpLink: '', wpName: null });

  // react query
  useEffect(() => {
    filterData = { ...filterData, ..._.pickBy(_.get(history, 'location.state', null), _.identity) };
    setFilter(filterData);
  }, [history]);

  async function fetchCareers(filter) {
    const params = _.pickBy(filter, _.identity);
    const { data } = await Axios.get(`${SERVER_URL}/careers`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
      params,
    });
    return data;
  }

  const { data, isError, isLoading } = useQuery({
    queryKey: ['careers', filter, fetchTrigger],
    queryFn: () => fetchCareers(filter),
    keepPreviousData: true,
  });
  if (isError) {
    notification.error({
      message: 'Error',
      description: 'Problem with fetching all careers.',
      placement: 'bottomRight',
    });
  }

  const deleteHandler = async (id) => {
    const career = data.items.find((item) => item._id === id);

    try {
      Modal.confirm({
        width: 700,
        icon: <ExclamationCircleOutlined style={{ display: 'block', fontSize: '2rem' }} />,
        content: (
          <div style={{ marginLeft: '3.5rem' }}>
            {career.websites?.length > 0 && (
              <p>This career has widgets on {career.websites.map((w) => w.url).join(', ')}</p>
            )}
            Are you sure you want to delete this career?
          </div>
        ),
        async onOk() {
          await Axios.delete(`${SERVER_URL}/career/${id}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });
          setFetchTrigger(!fetchTrigger);
          notification.success({
            message: 'Item is deleted.',
            placement: 'bottomRight',
          });
          Modal.destroyAll();
        },
        onCancel() {
          Modal.destroyAll();
        },
      });
    } catch (err) {
      console.log(err);
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const editHandler = async (id) => {
    const career = data.items.find((item) => item._id === id);
    setModal({ visible: true, id, careerName: career.careerName, wpLink: career.wpLink || '', wpName: career.wpName });
  };

  const refreshAll = async () => {
    setWorking(true);
    try {
      await Axios.put(
        `${SERVER_URL}/career-refresh-data`,
        {},
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
          timeout: 20 * 60 * 1000,
        },
      );

      notification.success({
        message: 'Careers refreshed.',
        placement: 'bottomRight',
      });

      setWorking(false);
      history.push('/admin');
      history.push('/admin/careers');
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Problem with refreshing careers. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const excelServerData = async () => {
    const { data } = await Axios.get(`${SERVER_URL}/careers-for-export`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    return data;
  };

  const getDataForExport = () => {
    if (data?.items && data?.items?.length > 0) {
      return data.items.map((item) => ({
        _id: item._id,
        'Career Name': item.careerName,
        'OCC CODE': item.occCode,
        'AVERAGE SALARY': item?.BLS?.A_MEDIAN,
        'TOTAL EMPLOYMENT': item?.BLS?.TOT_EMP,
        'ANNUAL OPPENINGS': item?.BLS?.annualOpenings,
        'ESTIMATED GROWTH INCREASE': item?.BLS?.estimatedIncrease,
        'JOBS PER 1000': item?.BLS?.JOBS_1000,
        'Location quotient': item?.BLS?.LOC_QUOTIENT,
      }));
    } else {
      return [];
    }
  };
  const getFileExportName = () => {
    let fileName = 'careers';
    if (filter.state) {
      fileName = `${fileName}_${filter.state.toLowerCase().split(' ').join('_')}`;
    }
    if (filter.year) {
      fileName = `${fileName}_${filter.year}`;
    }
    return fileName;
  };

  return (
    <div className='content-wrapper'>
      {isError && !isLoading && <Alert message={'Loading data problem...'} type='error' showIcon closable />}
      <Spin spinning={isWorking} tip='Working...'>
        <div className='actions-block flex'>
          <div>
            <Link to={isWorking ? '/admin/careers' : '/admin/new-career'}>
              <Tooltip title='Create new career'>
                <Button type='primary' style={{ marginRight: '8px' }} disabled={isWorking}>
                  NEW CAREER
                </Button>
              </Tooltip>
            </Link>

            <Tooltip title='Fetch new data for all careers'>
              <Button type='primary' danger onClick={refreshAll} disabled={isWorking}>
                SYNC ALL CAREERS
              </Button>
            </Tooltip>

            {!currentuser.data.role.includes('guest') && (
              <ExportToExcel
                csvData={getDataForExport}
                fileName={getFileExportName()}
                propsToRemove={['_id']}
                disabled={currentuser.data.role.includes('viewer')}
              />
            )}

            <Select
              value={filter.year}
              onChange={(value) => setFilter({ ...filter, year: value })}
              style={{ width: '200px', marginLeft: '4px' }}
              placeholder='Year...'
            >
              <Select.Option key='2023' value='2023'>
                2023
              </Select.Option>
              <Select.Option key='2022' value='2022'>
                2022
              </Select.Option>
              <Select.Option key='2021' value='2021'>
                2021
              </Select.Option>
              <Select.Option key='2020' value='2020'>
                2020
              </Select.Option>
              <Select.Option key='2019' value='2019'>
                2019
              </Select.Option>
              <Select.Option key='2018' value='2018'>
                2018
              </Select.Option>
              <Select.Option key='2017' value='2017'>
                2017
              </Select.Option>
              <Select.Option key='2016' value='2016'>
                2016
              </Select.Option>
            </Select>

            <Select
              showSearch
              optionFilterProp='children'
              value={filter.state}
              onChange={(value) => setFilter({ ...filter, state: value })}
              style={{ width: '200px', marginLeft: '4px' }}
              placeholder='State...'
            >
              {[{ name: 'United States' }, ...STATES].map((item) => (
                <Select.Option key={item.name} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <h2 style={{ margin: 0 }}>Total Careers: {data?.count || 0}</h2>
        </div>

        <div style={{ textAlign: 'center' }}>
          {isLoading && !data && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}

          {data?.items && (
            <CareerTable
              data={data?.items || []}
              deleteHandler={deleteHandler}
              title='Career'
              viewPath='/admin/view-career/'
              total={data?.count || 0}
              filter={filter}
              setFilter={setFilter}
              editHandler={editHandler}
              modal={modal}
              setModal={setModal}
              currentuser={currentuser}
              setWorking={setWorking}
              fetchTrigger={fetchTrigger}
              setFetchTrigger={setFetchTrigger}
            />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default function () {
  return (
    <QueryClientProvider client={queryClient}>
      <Careers />
    </QueryClientProvider>
  );
}
